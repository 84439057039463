<script>
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "2553814491384451");
    fbq("track", "PageView");
</script>
<noscript
    ><img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=2553814491384451&ev=PageView&noscript=1"
/></noscript>

<div class="cko-page">
    <form class="cko-page-inner">
        <md-checkout-loading
            [showCartSkeleton]="
                showCartSkeleton ||
                checkoutUtilsService.showGetPaymentMethodSkeleton
            "
        />
        <ng-container *ngIf="items && items.length > 0">
            <md-order-summary
                *ngIf="!showCartSkeleton"
                [cartItems]="items"
                [cart_total_amount]="cart_total_amount"
                [coupon_code_discount]="coupon_code_discount"
                [store_discount_amount]="store_discount_amount"
                [rw_discountAmount]="rw_discountAmount"
                [discountCodeTitle]="discountCodeTitle"
                [giftcard]="giftcard"
                [gift_discount]="gift_discount"
                [rewardPts]="rewardPts"
                [servicFeeVal]="servicFeeVal"
                [totalPrice]="totalPrice"
                [desktopOnly]="true"
                [afterCartUpdated]="updateCostsBasedOnResponse"
                [uncheckGiftAndApplyStoreCredit]="
                    uncheckGiftAndApplyStoreCredit
                " />
            <div
                class="cko-page-left"
                *ngIf="!showPaymentMethods && items.length > 0"
            >
                <md-reservation-details
                    [guests]="guests"
                    [switchPaymentCallback]="switchPayment"
                />
                <ng-container *ngTemplateOutlet="rewards"></ng-container>
                <md-booking-summary
                    mdMobileOnly
                    [cart_total_amount]="cart_total_amount"
                    [coupon_code_discount]="coupon_code_discount"
                    [store_discount_amount]="store_discount_amount"
                    [rw_discountAmount]="rw_discountAmount"
                    [discountCodeTitle]="discountCodeTitle"
                    [giftcard]="giftcard"
                    [gift_discount]="gift_discount"
                    [rewardPts]="rewardPts"
                    [servicFeeVal]="servicFeeVal"
                    [totalPrice]="totalPrice"
                    [standalone]="true"
                ></md-booking-summary>
                <button
                    *ngIf="totalPrice > 0"
                    class="chk-proceed-btn"
                    [ngClass]="{
                        'safari-margin-bottom': isSafari || isFirefox
                    }"
                    [disabled]="
                        !checkoutUtilsService.isGuestNameFilled ||
                        !checkoutUtilsService.areChildrenAgesValid
                    "
                    mdMainButton="light"
                    (click)="openModal(paymentModal)"
                >
                    {{ "Proceed to payment" | translate }}
                </button>
                <button
                    *ngIf="totalPrice <= 0"
                    class="chk-proceed-btn"
                    mdMainButton="light"
                    id="addtocart-btn"
                    (click)="openModal(checkboxesModal)"
                >
                    {{ "Complete Order" | translate }}
                </button>
            </div>
            <div class="cko-page-left" *ngIf="showPaymentMethods">
                <ng-container *ngTemplateOutlet="payment"></ng-container></div
        ></ng-container>
        <md-empty-cart
            *ngIf="
                !showCartSkeleton &&
                !checkoutUtilsService.showGetPaymentMethodSkeleton
            "
            [items]="items"
        />
    </form>
</div>
<ng-template #rewards>
    <h4 class="cko-rewards-title">
        2.{{
            " Add promo code, rewards, store credit, or gift card" | translate
        }}
    </h4>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="false">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton (click)="toggleApplyDiscountCode()">
                    <img src="assets/icons/checkout/discount_code.svg" />
                    {{ "Apply Discount Code" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                [(ngModel)]="discountCode"
                                [ngModelOptions]="{ standalone: true }"
                                ngDefaultControl
                                [disabled]="isDiscountCodeApplied"
                                [placeholder]="
                                    'Enter discount code' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                (click)="applyDiscountCode(discountCode)"
                                class="ripleEffect chk-rewards-item-dc-btn"
                                [disabled]="discountCode.length < 1"
                            >
                                {{
                                    (isDiscountCodeApplied
                                        ? "Cancel Coupon"
                                        : "Apply Discount Code"
                                    ) | translate
                                }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="storeLabel.can_show"
        ngbAccordion
        class="chk-rewards-item-container"
    >
        <div ngbAccordionItem [collapsed]="!(rw_slider?.slider_max > 0)">
            <h2 ngbAccordionHeader>
                <button
                    ngbAccordionButton
                    (click)="isRewardPoint = !isRewardPoint; calculateTotal()"
                >
                    <img src="assets/icons/checkout/Reward Points.svg" />
                    {{ "Spend Your Points" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-item-syp-subtitle">
                            {{ "You have " | translate }}
                            <b
                                >{{ rw_slider.slider_max
                                }}{{ " reward points " }}</b
                            >
                            {{ "on your account" | translate }}
                        </p>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="number"
                                [value]="usedRwPt > 0 ? usedRwPt : ''"
                                (ngInit)="usedRwPt = 0"
                                ngDefaultControl
                                [(ngModel)]="usedRwPt"
                                [placeholder]="
                                    'Enter your reward points' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                [disabled]="usedRwPt < 1"
                                (click)="
                                    isRewardPoint = true;
                                    updateRwPoint(usedRwPt)
                                "
                                class="ripleEffect chk-rewards-item-dc-btn"
                            >
                                {{ "Apply reward points" | translate }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="storeLabel.can_show"
        ngbAccordion
        class="chk-rewards-item-container"
    >
        <div ngbAccordionItem [collapsed]="false">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/Store credit.svg" />
                    {{ "Apply Store Credit" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-asc-p">
                            {{ storeLabel.storecredit_details.label }}
                        </p>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                disabled
                                [value]="
                                    store_discount_amount > 0
                                        ? store_discount_amount
                                        : ''
                                "
                                [placeholder]="
                                    'No Store Credit applied' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                (click)="
                                    isStorePoint = !isStorePoint;
                                    apply_store_credit(isStorePoint)
                                "
                                class="ripleEffect chk-rewards-item-dc-btn"
                            >
                                {{
                                    (!isStorePoint
                                        ? "Apply Store Credit"
                                        : "Remove Store Credit"
                                    ) | translate
                                }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="!(giftcardbalance > 0)">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/Gift card.svg" />
                    {{ "Use Gift Card" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-item-gc-p">
                            {{ "You have " | translate
                            }}<b
                                >{{
                                    giftcardbalance
                                        | currency
                                            : localeData.currency
                                            : "symbol"
                                            : "1.0-0"
                                            : localeData.localeForCurrency
                                }}{{ " balance" | translate }}</b
                            >{{ " on your Gift Card account" }}
                        </p>
                        <div>
                            <div
                                class="chk-rewards-gc-applied"
                                *ngFor="let giftCode of applyiedGiftCode"
                            >
                                {{ giftCode.hidden_code }}
                                ({{
                                    giftCode.applyiedBalance
                                        | currency
                                            : localeData.currency
                                            : "symbol"
                                            : "1.0-0"
                                            : localeData.localeForCurrency
                                }}
                                )
                                <ion-icon
                                    name="close-outline"
                                    (click)="remove_gift(giftCode)"
                                ></ion-icon>
                            </div>
                        </div>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                [ngModelOptions]="{ standalone: true }"
                                ngDefaultControl
                                [(ngModel)]="checkout.giftValue"
                                id="enter_giftcard"
                                [placeholder]="
                                    'Enter your gift Code' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                [disabled]="!checkout.giftValue"
                                class="ripleEffect chk-rewards-item-dc-btn fit-content"
                                (click)="addGift()"
                            >
                                {{ "Apply Gift Card" | translate }}
                            </button>
                        </div>
                        <div class="chk-rewards-item-middle">
                            <button
                                mdMainButton="light"
                                class="ripleEffect chk-rewards-item-dc-btn fit-content"
                                (click)="goToGiftCardManagement()"
                            >
                                {{ "Add new Gift card" | translate }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #checkboxes>
    <label
        *ngIf="payment_method_with_button_enabled != '1'"
        (click)="checkconditiononcheckout()"
        class="cko-checkboxes-label"
    >
        <label class="custom-checkbox">
            <input
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
                value="i_read"
                ngDefaultControl
                [(ngModel)]="checkout.termsAndConditions"
            />
            <span class="checkmark"></span>
        </label>
        <span
            >{{ "I have read and agree to the" | translate
            }}<span> {{ "terms and conditions" | translate }}</span></span
        >
    </label>
    <label
        *ngIf="payment_method_with_button_enabled != '1'"
        (click)="checkconditiononcheckout()"
        class="cko-checkboxes-label"
    >
        <label class="custom-checkbox">
            <input
                type="checkbox"
                value="i_am_mauritian"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [(ngModel)]="checkout.iAmMauritian"
            />
            <span class="checkmark"></span>
        </label>
        <span>{{
            reunionGeneral
                ? "Confirm that I am a Reunionese Citizen or Resident"
                : ("Confirm that I am a Mauritian Citizen or Resident"
                  | translate)
        }}</span>
    </label>
</ng-template>
<ng-template #payment>
    <md-booking-summary
        [cart_total_amount]="cart_total_amount"
        [coupon_code_discount]="coupon_code_discount"
        [store_discount_amount]="store_discount_amount"
        [rw_discountAmount]="rw_discountAmount"
        [discountCodeTitle]="discountCodeTitle"
        [giftcard]="giftcard"
        [gift_discount]="gift_discount"
        [rewardPts]="rewardPts"
        [servicFeeVal]="servicFeeVal"
        [totalPrice]="totalPrice"
        [desktopOnly]="false"
        [standalone]="true"
    />
    <div *ngFor="let payment of paymentList">
        <ng-container
            *ngIf="
                totalPrice > 0 &&
                checkoutUtilsService.isGuestNameFilled &&
                isPaymentMethodShown(group)
            "
        >
            <div
                class="cko-payment-option"
                *ngIf="payment.payment_method_newStatus == 1"
                [ngClass]="{
                    open:
                        checkout.selectedPaymentMethod ==
                        payment.payment_method_id
                }"
                [id]="'pmid' + payment.payment_method_id"
            >
                <label>
                    <input
                        type="radio"
                        ngDefaultControl
                        [(ngModel)]="checkout.selectedPaymentMethod"
                        (click)="
                            getPaymentMethod(
                                payment,
                                'pmid' + payment.payment_method_id
                            )
                        "
                        [value]="payment.payment_method_id"
                        [ngModelOptions]="{ standalone: true }"
                        name="paymentRadioGroup"
                    /><img
                        [src]="
                            getImageSourceBasedOnCode(
                                payment.payment_method_code
                            )
                        "
                    />{{ payment.payment_method_title }}</label
                >
            </div>
            <div
                style="height: 600px"
                class="cko-payment-option-value"
                *ngIf="
                    payment.payment_method_code == 'mipspaymentsystemiframe' &&
                    checkout.slctdPymntMthd == 'mipspaymentsystemiframe'
                "
            >
                <iframe
                    id="mipsIframe"
                    class="cko-payment-card-iframe"
                    frameborder="no"
                    scrolling="no"
                ></iframe>
                <md-mips-loading *ngIf="mipsIframeLoading" />
            </div>
            <div
                class="cko-payment-option-value direct"
                *ngIf="
                    payment.payment_method_code == 'banktransfer' &&
                    checkout.slctdPymntMthd == 'banktransfer'
                "
            >
                <ng-container
                    *ngTemplateOutlet="asyncPaymentMethod"
                ></ng-container>
            </div>
            <div
                class="cko-payment-option-value direct"
                *ngIf="
                    payment.payment_method_code == 'cashondelivery' &&
                    checkout.slctdPymntMthd == 'cashondelivery'
                "
            >
                <ng-container
                    *ngTemplateOutlet="asyncPaymentMethod"
                ></ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #asyncPaymentMethod>
    <p class="cko-apm-text">
        MariDeal operates on a first come first serve basis. Limited-time offers
        can sell out at any time. Offer will remain pending in your cart for 48
        hours before cancellation if payment is not confirmed.
    </p>
    <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
    <button
        [disabled]="!checkout.termsAndConditions || !checkout.iAmMauritian"
        mdMainButton="light"
        id="addtocart-btn"
        class="ripleEffect cko-payment-option-btn"
        (click)="submitAddress()"
    >
        {{ "Place Order" | translate }}
    </button>
</ng-template>

<md-modal-offcanvas
    #checkboxesModal
    [mobileModalTitle]="
        'Are you sure you want to complete this order?' | translate
    "
>
    <div class="cko-checkboxes-container" actualControl>
        <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
        <button
            [disabled]="!checkout.termsAndConditions || !checkout.iAmMauritian"
            class="chk-proceed-btn"
            mdMainButton="light"
            id="addtocart-btn"
            (click)="submitAddress()"
        >
            {{ "Complete Order" | translate }}
        </button>
    </div>
</md-modal-offcanvas>
<md-modal-offcanvas
    #paymentModal
    [mobileModalTitle]="'Payment methods' | translate"
>
    <ng-container actualControl *ngTemplateOutlet="payment"></ng-container>
</md-modal-offcanvas>
