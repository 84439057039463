<div>
    <app-filter-data
        *ngIf="screenSizeService.isMobileWidth | async"
        [idsAlreadyAppliedFilters]="filterIdsFromQueryString2"
        (applyFilters)="applyFilters($event)"
        (sort)="applySorting($event)"
    >
    </app-filter-data>
    <div class="md-container">
        <div class="category-deals-list-component-parent-div">
            <div
                infiniteScroll
                [infiniteScrollContainer]="scrollablePane"
                [infiniteScrollDistance]="3"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                [immediateCheck]="true"
                [infiniteScrollDisabled]="loadingMoreDeals"
                [alwaysCallback]="true"
                (scrolled)="onScroll()"
            >
                <md-banner-reel
                    *ngIf="!(screenSizeService.isMobileWidth | async)"
                    [categoryId]="sectionId"
                ></md-banner-reel>

                <!-- Category deals cards -->
                <div class="md-category-listings row">
                    <app-filter-data
                        class="col-sm-3"
                        *ngIf="!(screenSizeService.isMobileWidth | async)"
                        [idsAlreadyAppliedFilters]="filterIdsFromQueryString2"
                        (applyFilters)="applyFilters($event)"
                        (sort)="applySorting($event)"
                    >
                    </app-filter-data>
                    <div class="col-md-9">
                        <md-category-sort-bar
                            *ngIf="!(screenSizeService.isMobileWidth | async)"
                            [showClosestDate]="sectionId === 19"
                            [sortLabel]="filterSortLabel"
                            (sort)="applySorting($event)"
                        ></md-category-sort-bar>

                        <div *ngIf="busyLoadingData">
                            <md-product-list-item-loading
                                *ngFor="let item of [].constructor(4)"
                            ></md-product-list-item-loading>
                        </div>

                        <!-- Iterate through the product listings -->
                        <div
                            *ngFor="let data of categoryData; let i = index"
                            (click)="redirectToProdDetail(data.id, data.productPath)"
                        >
                            <md-product-list-item
                                [data]="data"
                                [index]="index"
                                [currencySymbol]="currencySymbol"
                                [locale]="locale"
                            ></md-product-list-item>
                            
                            <!-- Ad Banners Display -->
                            <div (click)="onParentDivClick($event)">
                                <div *ngIf="i === 7 && sectionId === 3" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 7 && sectionId === 8" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl1" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 7 && sectionId === 6" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl2" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 7 && sectionId === 4" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl3" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 7 && sectionId === 7" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl4" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 7 && sectionId === 9" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl5" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                                
                                <div *ngIf="i === 5 && sectionId === 19" class="list-banner-content">
                                    <div class="list-banner-container">
                                        <a href="https://marideal.mu/plp/Gift%20Card/22" target="_blank">
                                            <img [src]="bannerUrl6" class="ad-banner-img" (click)="onImageClick($event)"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                
                            <!-- Loading more items indicator -->
                            <div
                                [hidden]="!loadingMoreDeals"
                                *ngIf="
                                    categoryData.length < totalRecords &&
                                    categoryData.length >= 20 &&
                                    i + 1 == categoryData.length
                                "
                            >
                                <div
                                    class="view_more custom-user-review-div-23"
                                >
                                    {{ "Loading more" | translate }}
                                </div>
                            </div>
                        </div>

                        <!-- No products message -->
                        <div
                            class="row no-product"
                            *ngIf="
                                categoryData?.length === 0 && !busyLoadingData
                            "
                        >
                            {{
                                "There is no product in selected category"
                                    | translate
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #bannerTemplate let-banner="banner">
    <div class="col-sm-12" *ngIf="i == 4">
        <swiper
            *ngIf="screenSizeService.isMobileWidth | async; else desktopBanners"
            [config]="bannerConfig"
        >
            <div class="swiper-wrapper">
                <img
                    (click)="goToPromotionLink(img.urlString)"
                    class="swiper-slide"
                    *ngFor="let img of banner"
                    src="assets/img/defaultIcon.png"
                    (error)="onImageError($event)"
                    [src]="img.filename"
                    alt=""
                />
            </div>
        </swiper>
        <ng-template #desktopBanners>
            <swiper [config]="bannerConfig">
                <div class="swiper-wrapper">
                    <img
                        (click)="goToPromotionLink(img.urlString)"
                        class="swiper-slide"
                        *ngFor="let img of banner"
                        src="assets/img/defaultIcon.png"
                        (error)="onImageError($event)"
                        [src]="img.filenameDesktop"
                        alt=""
                    />
                </div>
            </swiper>
        </ng-template>
    </div>
</ng-template>
