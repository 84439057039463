import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { CategoryListService } from './category-deals-list.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { combineLatest, Subscription } from 'rxjs';
import { CategoryProducts } from './model/category-products.model';
import { Product } from './model/product.model';
import { ScreenSizeService } from '../services/screen-size.service';
import {
    AppliedFilterIds,
    Filter,
    SortLabel,
} from '../filter-data/filter-data.model';
import { EVENT_CATEGORY, HOTEL_CATEGORY } from '../static-content/menu-routes';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';
import { DeviceCommsService } from '../services/device-comms.service';
import { getRewardPointsMoney } from '../utilities/user.utils';
import {
    addFilterIdsToQueryStringAndSearch,
    addSortingDataToQueryString,
} from './category-sort-bar/category-sort-bar.utils';
import {
    addClassToSearchDatesComponent,
    removeClassFromSearchDatesComponent,
} from '../utilities/utils';
import { RouteParamsService } from '../services/route-params.service';
import { screenSizes } from '../utilities/theme';

declare let $: any;

@Component({
    selector: 'app-category-list',
    templateUrl: './category-deals-list.component.html',
    styleUrls: ['./category-deals-list.component.scss'],
})
export class CategoryListComponent implements OnInit, OnDestroy {
    categoryData: Product[] = [];
    subscription: Subscription = Subscription.EMPTY;
    loadingMoreDeals = false;

    filterIdsFromQueryString2: AppliedFilterIds[] = [];
    appliedFilterIds2: AppliedFilterIds[] = [];
    

    filterSortLabel: SortLabel;
    bannerConfig: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        autoplay: true,
    };
    
    totalRecords: number = 0;
    busyLoadingData: boolean = true;

    scrollablePane: Element;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    rewardPointsMoney: number = 0;
    localeData: LocaleData;
    urlFilterParams: string;
    filterIds: AppliedFilterIds[] = [];

    public sectionId: number;
    private currentPage = 1;
    

    constructor(
        public categoryService: CategoryListService,
        public common: CommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public screenSizeService: ScreenSizeService,
        private currencyLanguageService: CurrencyLanguageService,
        private deviceCommsService: DeviceCommsService,
        private routeParamsService: RouteParamsService
    ) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
        removeClassFromSearchDatesComponent();
        window.removeEventListener('resize', () => this.updateBannerUrls());
    }

    bannersConfig = {
        banner: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner1: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner2: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner3: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner4: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner5: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        },
        banner6: {
            mobile: 'assets/img/banners/MobPLP.jpg',
            desktop: 'assets/img/banners/PLP.jpg'
        }
    };
    
    bannerUrl: string;
    bannerUrl1: string;
    bannerUrl2: string;
    bannerUrl3: string;
    bannerUrl4: string;
    bannerUrl5: string;
    bannerUrl6: string;

    onParentDivClick(event: MouseEvent) {
        // Prevent the parent div's click event from triggering navigation
        event.preventDefault();
        event.stopPropagation();
    }
    
    onImageClick(event: MouseEvent) {
        // Allow the image's click event to proceed with navigation
        event.stopPropagation(); // This stops the event from bubbling up to the parent div
    }
    

    ngOnInit(): void {
        this.getCategoryData();
        document.body.scrollTop = 0;
        this.scrollablePane = document.getElementsByClassName('pane')[0];
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.rewardPointsMoney = getRewardPointsMoney();
        addClassToSearchDatesComponent();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.appliedFilterIds2 = [];
            }
        });
        this.urlFilterParams = this.activatedRoute.queryParams['filters'];

        this.updateBannerUrls();
        window.addEventListener('resize', () => this.updateBannerUrls());
    }

    private getFilterIdsFromParamsAndFilterComponent(): AppliedFilterIds[] {
        return this.appliedFilterIds2.length > 0 ? this.appliedFilterIds2 : this.filterIdsFromQueryString2;
    }

    private updateBannerUrls(): void {
        const screenWidth = window.innerWidth;
        const bannerMappings = {
            bannerUrl: 'banner',
            bannerUrl1: 'banner1',
            bannerUrl2: 'banner2',
            bannerUrl3: 'banner3',
            bannerUrl4: 'banner4',
            bannerUrl5: 'banner5',
            bannerUrl6: 'banner6' 
        };
    
        for (const [property, configKey] of Object.entries(bannerMappings)) {
            this[property] = this.getBannerUrl(screenWidth, this.bannersConfig[configKey]);
        }
    }
    
    
    private getBannerUrl(screenWidth: number, config: { mobile: string, desktop: string }): string {
        return screenWidth < screenSizes.mobile ? config.mobile : config.desktop;
    }

    private parseFilterParams(filterParams: string): AppliedFilterIds[] {
        return filterParams
            ? filterParams.split(',').map(filter => {
                const [filterTypeId, filterId] = filter.split('-');
                return {
                    filterTypeId: +filterTypeId,
                    filterId: +filterId,
                };
            })
            : [];
    }
    

    getCategoryData() {
        this.subscription = combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.queryParams,
            this.currencyLanguageService.getLocaleData(),
        ]).subscribe(([params, queryParams, locale]) => {
            this.busyLoadingData = true;
            this.sectionId = Number(params['sectionId']);
            this.routeParamsService.setSectionId(this.sectionId);
            const filterParams: string = queryParams['filters'];
            this.urlFilterParams = filterParams;
            this.filterIdsFromQueryString2 = this.parseFilterParams(filterParams);

            const sortParam: string = queryParams['sort'];
            if (sortParam) {
                this.filterSortLabel = sortParam as SortLabel;
            }

            const filterIdsToApply =
                this.getFilterIdsFromParamsAndFilterComponent();
            this.localeData = locale;
            // the part of this call that still goes to Magento is only used for banners and such
            this.categoryService
                .getCategoryDetails(
                    filterIdsToApply,
                    this.filterSortLabel,
                    locale.language,
                    this.sectionId,
                    locale.country
                )
                .subscribe({
                    next: (response: CategoryProducts) => {
                        this.busyLoadingData = false;
                        this.totalRecords = response.total;
                        this.categoryData = [...response.data];

                        if (this.categoryService.scrollPosition) {
                            setTimeout(() => {
                                this.scrollablePane.scrollTop =
                                    this.categoryService.scrollPosition;
                            }, 0);
                        }
                    },
                });
        });
    }

    onScroll() {
        if (this.busyLoadingData) {
            return;
        }

        if (this.categoryData.length < this.totalRecords) {
            this.loadingMoreDeals = true;
            this.currentPage += 1;
            
            this.filterIds = this.parseFilterParams(this.urlFilterParams);
            
            this.categoryService
                .getMoreCategoryDetails(
                    this.currentPage,
                    this.filterIds,
                    this.filterSortLabel,
                    this.localeData.language,
                    this.sectionId,
                    this.localeData.country
                )
                .subscribe({
                    next: (response: CategoryProducts) => {
                        this.categoryData = response.data;
                        this.loadingMoreDeals = false;
                    },
                });
        }
    }

    applyFilters(filters: Filter[]) {
        this.appliedFilterIds2 = filters
            .filter((f) => f.isSelected)
            .flatMap((f) =>
                f.filterValues
                    .filter((fv) => fv.isSelected)
                    .map((fv) => {
                        return {
                            filterTypeId: f.filterTypeId,
                            filterId: fv.filterId,
                        };
                    })
            );
        addFilterIdsToQueryStringAndSearch(this.router, this.appliedFilterIds2);
        this.currentPage = 1;
    }

    applySorting(sortLabel: SortLabel) {
        this.filterSortLabel = sortLabel;
        addSortingDataToQueryString(this.router, sortLabel);
        this.currentPage = 1;
    }

    onImageError(event: any) {
        event.target.src = 'assets/img/defaultIcon.png';
    }

    redirectToProdDetail(id: number, productPath: string) {
        this.common.clearCustomCache(this);
        this.categoryService.scrollPosition = this.scrollablePane.scrollTop;

        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `/deal/${productPath}/${id}`
        );
    }

    goToPromotionLink(link: string) {
        this.deviceCommsService.openLinkInNewTabOrBrowser(link);
    }
}
