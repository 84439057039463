import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'md-modal-offcanvas',
    templateUrl: './modal-offcanvas.component.html',
    styleUrls: ['./modal-offcanvas.component.scss'],
})
export class ModalOffcanvasComponent {
    @Input() mobileModalTitle: string;
    @Input() thinPadding: boolean;

    hasRenderedAsMobile: boolean;

    @ViewChild('mobileControl') mobileControl: TemplateRef<unknown>;
    @ViewChild('modal') modal: TemplateRef<unknown>;

    constructor(
        private offcanvasService: NgbOffcanvas,
        private modalService: NgbModal
    ) {}

    open() {
        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.open(this.mobileControl, {
                position: 'bottom',
                panelClass: 'c-o-modal-offcanvas',
                scroll: true,
            });
        } else {
            this.openModal();
        }
    }

    onModalCloseButtonClicked() {
        this.close();
    }

    onModalContinueClick() {
        this.close();
    }

    close() {
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.dismiss();
        } else {
            this.modalService.dismissAll();
        }
    }

    openModal() {
        this.modalService.open(this.modal, {
            centered: true,
        });
    }
}
